import React from 'react';
import {
  ContactSection,
  HeroSection,
  InterestsSection,
  Page,
  Seo
} from 'gatsby-theme-portfolio-minimal';

export default function IndexPage() {
  return (
    <>
      <Seo title="Lúcia Duarte · Software Developer" />
      <Page>
        <HeroSection sectionId="hero" />
        <InterestsSection sectionId="technologies" heading="Technologies" />
        <ContactSection sectionId="contact" heading="Contact Details" />
      </Page>
    </>
  );
}
